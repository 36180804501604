.carousel {
    display: flex;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.carousel__item {
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
}
