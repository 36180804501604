.button {
    font-family: var(--sans-font);
    font-size: 0.85em;
    line-height: inherit;
    hyphens: auto;
    width: 100%;
    padding: 0;
    background-color: var(--bg-colour, var(--blue));
    border: 0.1em solid #000;
    border-radius: 0.2em;
    box-shadow:
        inset 0.1em 0.1em 0.2em rgb(255 255 255 / 30%),
        inset -0.1em -0.1em 0.2em rgb(0 0 0 / 30%),
        0.1em 0.1em 0.2em rgb(0 0 0 / 40%);
    color: #000;
    padding-left: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;

    // &[aria-disabled="true"],
    &[disabled] {
        opacity: 0.7;
        box-shadow: none;
        color: #333;
        border-color: #333;
        filter: grayscale(0.5);
        cursor: not-allowed;
    }
}

.button--warning {
    background-color: var(--orange);
}

.button--full-height {
    height: 100%;
}

.is-loading > .button__text {
    display: none;
}

.button__loader {
    position: absolute;
    opacity: 0;

    @at-root {

        .is-loading > & {
            position: static;
            opacity: 1;
        }

    }
}
