.input {
    --border-radius: 0.2em;
    display: block;
    border: 0.1em solid #000;
    border-radius: var(--border-radius);
    width: 100%;
    box-shadow:
        0.1em 0.1em 0.2em rgb(0 0 0 / 40%),
        inset 0.1em 0.1em 0.2em rgb(0 0 0 / 30%);
    padding: 0 0.3em;
    background-color: #f5f5f5;
    font-family: var(--sans-font);
    font-size: .85em;
    line-height: inherit;
}

.input--tall {
    min-height: calc(1.4em * 5.5);
}
