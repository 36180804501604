.file-upload {
    display: grid;
}

.file-upload__input,
.file-upload__render {
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
}

.file-upload__input {
    z-index: 1;
    opacity: 0;
}

.file-upload__render {
    --border-radius: 0.2em;
    display: flex;
    box-shadow: 0.1em 0.1em 0.2em rgb(0 0 0 / 40%);
    border-radius: var(--border-radius);
    cursor: pointer;
    font-family: var(--sans-font);
    font-size: .85em;
    min-width: 0;

    &::before {
        content: attr(data-value);
        flex-grow: 1;
        border: 0.1em solid #000;
        border-radius: var(--border-radius) 0 0 var(--border-radius);
        box-shadow: inset 0.1em 0.1em 0.2em rgb(0 0 0 / 30%);
        padding: 0 0.3em;
        background-color: #f5f5f5;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &::after {
        content: attr(data-button);
        border: 0.1em solid #000;
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
        background-color: #679fd0;
        box-shadow:
            inset 0.1em 0.1em 0.2em rgb(255 255 255 / 30%),
            inset -0.1em -0.1em 0.2em rgb(0 0 0 / 30%);
        padding: 0 0.3em;
    }

    @at-root {
        :focus-visible + #{&} {
            outline: Highlight auto 1px;
            outline: -webkit-focus-ring-color auto 1px;
            outline-offset: 2px;
        }

        [dir="rtl"] & {

            &::before {
                border-radius: 0 var(--border-radius) var(--border-radius) 0;
            }

            &::after {
                border-radius: var(--border-radius) 0 0 var(--border-radius);
            }

        }
    }
}
