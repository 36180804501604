@font-face {
    font-family: "Metamorphous";
    src:
        url("../../fonts/Metamorphous.woff2") format("woff2"),
        url("../../fonts/Metamorphous.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Goudy Mediaeval";
    src:
        url("../../fonts/Goudy-Mediaeval-Regular.woff2") format("woff2"),
        url("../../fonts/Goudy-Mediaeval-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vinque";
    src:
        url("../../fonts/Vinque.woff2") format("woff2"),
        url("../../fonts/Vinque.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Serif Simplified Chinese";
    src:
        url("../../fonts/NotoSerifSC-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Serif Traditional Chinese";
    src:
        url("../../fonts/NotoSerifTC-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Serif Japanese";
    src:
        url("../../fonts/NotoSerifJP-Regular.woff2") format("woff2"),
        url("../../fonts/NotoSerifJP-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
