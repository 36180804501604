@charset "UTF-8";
@font-face {
  font-family: "Metamorphous";
  src: url("../../fonts/Metamorphous.woff2") format("woff2"), url("../../fonts/Metamorphous.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Goudy Mediaeval";
  src: url("../../fonts/Goudy-Mediaeval-Regular.woff2") format("woff2"), url("../../fonts/Goudy-Mediaeval-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Vinque";
  src: url("../../fonts/Vinque.woff2") format("woff2"), url("../../fonts/Vinque.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif Simplified Chinese";
  src: url("../../fonts/NotoSerifSC-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif Traditional Chinese";
  src: url("../../fonts/NotoSerifTC-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif Japanese";
  src: url("../../fonts/NotoSerifJP-Regular.woff2") format("woff2"), url("../../fonts/NotoSerifJP-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --serif-font:
      Metamorphous,
      "Goudy Mediaeval",
      Georgia,
      "Times New Roman",
      Times,
      serif;
  --sans-font:
      Helvetica,
      Arial,
      sans-serif;
  --blue: #679fd0;
  --orange: #d09f67;
  --brown: #9f9f30;
  --red: #d06767;
  --cyan: #67d0d0;
  --dark-orange: #a16930;
  --green: #679f67;
  --dark-purple: #9f679f;
  --lime: #9fd067;
  --yellow: #d0d067;
  --purple: #d067d0;
  --grey: #999;
  --good-team: #1d82ff;
  --evil-team: #ff1d1d;
}

:lang(ru),
:lang(uk),
:lang(vi) {
  --serif-font:
      Vinque,
      Georgia,
      "Times New Roman",
      Times,
      serif;
}

:lang(he) {
  --serif-font:
      Georgia,
      "Times New Roman",
      Times,
      serif;
}

:lang(zh-CN) {
  --serif-font:
      Georgia,
      "Times New Roman",
      "Noto Serif Simplified Chinese",
      "FangSong",
      "仿宋",
      STFangSong,
      "华文仿宋",
      serif;
  --sans-font:
      Helvetica,
      Arial,
      "华文细黑",
      "STXihei",
      "PingFang TC",
      "微软雅黑体",
      "Microsoft YaHei New",
      "微软雅黑",
      "Microsoft Yahei",
      "宋体",
      "SimSun",
      sans-serif;
}

:lang(zh-TW) {
  --serif-font:
      Georgia,
      "Times New Roman",
      "Noto Serif Traditional Chinese",
      "FangSong",
      "仿宋",
      STFangSong,
      "华文仿宋",
      serif;
  --sans-font:
      Helvetica,
      Arial,
      "华文细黑",
      "STXihei",
      "PingFang TC",
      "微软雅黑体",
      "Microsoft YaHei New",
      "微软雅黑",
      "Microsoft Yahei",
      "宋体",
      "SimSun",
      sans-serif;
}

:lang(ja-JP) {
  --serif-font:
      Georgia,
      "Times New Roman",
      "Noto Serif Japanese",
      "ＭＳ Ｐ明朝",
      "MS PMincho",
      "Hiragino Mincho Pro",
      "ヒラギノ明朝 Pro W3",
      "游明朝",
      "YuMincho",
      "ＭＳ 明朝",
      "MS Mincho",
      "Hiragino Mincho ProN",
      serif ;
  --sans-font:
      Helvetica,
      Arial,
      "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro",
      Osaka,
      メイリオ,
      Meiryo,
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "ＭＳ ゴシック",
      "MS Gothic",
      "Noto Sans CJK JP",
      TakaoPGothic,
      sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  font-size: 1rem;
  line-height: 1.4;
  font-family: var(--sans-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #000;
  min-height: 100vh;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  padding: 1em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  color: var(--blue);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  margin-bottom: 1rem;
}
h1:where(:last-child),
h2:where(:last-child),
h3:where(:last-child),
h4:where(:last-child),
h5:where(:last-child),
h6:where(:last-child),
p:where(:last-child),
li:where(:last-child) {
  margin-bottom: 0;
}

small {
  font-size: 0.85em;
}

[hidden] {
  display: none !important;
}

.token-display, .character-select__characters, .button-list,
.slot {
  --columns: 1;
  --gap: 1em;
  display: flex;
  flex-flow: row wrap;
  gap: var(--gap);
}
.token-display > *, .character-select__characters > *, .button-list > *,
.slot > * {
  margin: 0;
  width: calc(100% / var(--columns) - var(--gap) * (var(--columns) - 1) / var(--columns));
}

.slot--2 {
  --columns: 2;
}

.slot--3 {
  --columns: 3;
}

.slot--4 {
  --columns: 4;
}

.token-display__remove, .character-choice, .character-select__button, .info-token__button, .info-token__hide, .dialog__hide,
.no-btn {
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
}

.token-display, .button-list,
.no-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.radio__input,
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.filter-list:not(.is-show-all) > *:not(.is-included) {
  display: none;
}

.body-grimoire {
  background-color: #d0d0d0;
  background-image: url("../../img/background-img.jpg");
  background-repeat: repeat;
  background-position: 0 0;
}

.body-sheet {
  background-color: #fffef0;
  background-image: url("../../img/token/character-token-noise.png");
}

.title {
  font-size: clamp(1.5em, 5vw, 3em);
  font-family: var(--serif-font);
  font-weight: bold;
  letter-spacing: 0.05em;
  text-shadow: -0.05em -0.05em 0.1em rgba(255, 255, 255, 0.8), 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.4);
  margin-bottom: 1rem;
}

.pad {
  border: 0.15em solid #000;
  position: relative;
  background-color: #333;
  background-image: url("../../img/background-img2.jpg");
}

.pad--growable {
  height: 80vh;
  resize: vertical;
  overflow: scroll;
}

.pad--bluffs {
  padding: 0.5em;
}

.pad-wrapper {
  position: relative;
  margin-bottom: 1em;
}

.pad-wrapper__icon {
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0.15em;
  pointer-events: none;
  width: 1em;
  aspect-ratio: 1/1;
}
[dir=rtl] .pad-wrapper__icon {
  right: auto;
  left: 0;
}

.pad__title {
  font-family: var(--serif-font);
  font-weight: normal;
  font-size: 1rem;
  text-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.8);
}

.token {
  width: 60px;
  aspect-ratio: 1/1;
  border: none;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transform-origin: 0 0;
}
.token[data-token=character] {
  width: clamp(70px, 16.6666666667vw, 150px);
  transform: scale(var(--token-size, 1));
}
.token[data-token=reminder] {
  width: clamp(55px, 11.1111111111vw, 125px);
  transform: scale(var(--reminder-size, 1));
}
.token[disabled] {
  opacity: 0.7;
}
.token[disabled] > .character {
  --shadow-amount: 0;
}

.token--movable {
  --left: 0;
  --top: 0;
  --z-index: 0;
  cursor: move;
  position: absolute;
  left: calc(var(--left) * 1px);
  top: calc(var(--top) * 1px);
  z-index: var(--z-index);
  touch-action: none;
}

.token--bluff[data-token] {
  transform: none;
}

.character,
.reminder {
  border-radius: 50%;
  display: grid;
  width: 100%;
  aspect-ratio: 1/1;
}

.character {
  --no-leaf: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  --top-leaf: var(--no-leaf);
  --left-leaf: var(--no-leaf);
  --right-leaf: var(--no-leaf);
  --setup-leaf: var(--no-leaf);
  --reminder-offset: 2.5%;
  --shadow-offset: 0.1em;
  --shadow-rotation: 1;
  --shadow-amount: calc(var(--shadow-offset) * var(--shadow-rotation));
  background-color: #fffef0;
  background-image: var(--left-leaf), var(--top-leaf), var(--right-leaf), var(--setup-leaf), url("../../img/token/clockface-2.png"), url("../../img/token/character-token-noise.png");
  background-position: 50% 50%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, repeat;
  background-size: contain, contain, contain, contain, 60%, auto;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.2), inset 0 0 0.7em #8a4d0f, var(--shadow-amount) var(--shadow-amount) 0.2em rgba(0, 0, 0, 0.8);
}
.character.is-upside-down {
  --shadow-rotation: -1;
  transform: rotate(180deg);
}
.character.is-dead {
  filter: grayscale(1) brightness(0.8);
}

.character--top-1 {
  --top-leaf: url("../../img/token/top-1.png");
}

.character--top-2 {
  --top-leaf: url("../../img/token/top-2.png");
}

.character--top-3 {
  --top-leaf: url("../../img/token/top-3.png");
}

.character--top-4 {
  --top-leaf: url("../../img/token/top-4.png");
}

.character--top-5 {
  --top-leaf: url("../../img/token/top-5.png");
}

.character--top-6 {
  --top-leaf: url("../../img/token/top-6.png");
}

.character--left-1 {
  --left-leaf: url("../../img/token/left-1.png");
}

.character--right-1 {
  --right-leaf: url("../../img/token/right-1.png");
}

.character--right-2 {
  --right-leaf: url("../../img/token/right-2.png");
}

.character--setup {
  --setup-leaf: url("../../img/token/setup.png");
}

.character[data-first-night]::before,
.character[data-other-night]::after {
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  width: 25%;
  aspect-ratio: 1/1;
  display: var(--night-order-display, flex);
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  font-size: clamp(0.75em, 3.5vw - 0.5em, 2em);
  line-height: 1;
  grid-area: 1/-1;
  z-index: 2;
  margin-top: var(--reminder-offset);
}

.character.is-upside-down[data-first-night]::before,
.character.is-upside-down[data-other-night]::after {
  transform: rotate(180deg);
  margin-top: 0;
  margin-bottom: var(--reminder-offset);
  align-self: flex-end;
}

.character[data-first-night]::before {
  content: attr(data-first-night);
  background-image: linear-gradient(to bottom, #006, #00c);
  margin-left: var(--reminder-offset);
  margin-right: auto;
}

.character.is-upside-down[data-first-night]::before {
  margin-left: auto;
  margin-right: var(--reminder-offset);
}

.character[data-other-night]::after {
  content: attr(data-other-night);
  background-image: linear-gradient(to bottom, #600, #c00);
  margin-left: auto;
  margin-right: var(--reminder-offset);
}

.character.is-upside-down[data-other-night]::after {
  margin-left: var(--reminder-offset);
  margin-right: auto;
}

.reminder {
  background-color: #101128;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.1), inset 0 0 1em #111324, inset 0 0 0.5em #111324, 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.8);
}

.character__image,
.character__text,
.character__shroud,
.reminder__image,
.reminder__text {
  grid-area: 1/-1;
  z-index: 1;
}

.character__image,
.reminder__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.character__shroud {
  --background-width: calc(75% * (233 / 419));
  z-index: 2;
  background-image: url("../../img/token/shroud.png"), linear-gradient(to bottom, #555 57%, transparent 57%);
  background-size: auto 75%, var(--background-width) auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  filter: drop-shadow(0.1em 0.1em 0.2em rgba(0, 0, 0, 0.8));
  display: none;
}
.is-upside-down > .character__shroud {
  transform: rotate(180deg);
}

.is-dead > .character__shroud {
  display: block;
}

.character__ghost-vote {
  color: #fff;
  width: 30%;
  transform: translateY(100%);
}
.is-voteless .character__ghost-vote {
  display: none;
}

.character__icon {
  width: 90%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.character__name {
  font-family: var(--serif-font);
  font-weight: bold;
  letter-spacing: 0.1em;
}

.character__name:lang(ru), .character__name:lang(uk), .character__name:lang(vi), .character__name:lang(zh-TW) {
  font-weight: normal;
}

.character__player-name {
  display: block;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #fffef0;
  background-image: url("../../img/token/character-token-noise.png");
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.2), inset 0 0 0.7em #8a4d0f, var(--shadow-amount) var(--shadow-amount) 0.2em rgba(0, 0, 0, 0.8);
  font-size: 0.8em;
}
.character__player-name:empty {
  display: none;
}
.is-upside-down > .character__player-name {
  --shadow-amount: var(--shadow-offset);
  top: auto;
  bottom: 100%;
  transform: rotate(180deg);
}

.character__unknown {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
}
[disabled] .character__unknown {
  display: none;
}

.reminder__text {
  color: #fff;
  font-size: 1.2em;
  letter-spacing: 0.05em;
}

.reminder__icon {
  width: 70%;
}

.details {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.5em;
  border-radius: 0.5em;
  box-shadow: inset 0.15em 0.15em 0.3em rgba(0, 0, 0, 0.8), 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.8);
  margin-bottom: 1em;
}

.details__summary {
  font-family: var(--serif-font);
  text-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.details__heading {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: inline;
}

.details__body {
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.dialog {
  background-color: transparent;
  border: none;
  margin: auto;
  padding: 0;
}

.dialog--blur-background::backdrop {
  background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.7) 100%);
  backdrop-filter: blur(0.5em);
}

.dialog--blur-background + .backdrop {
  background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.7) 100%);
  backdrop-filter: blur(0.5em);
}

.dialog--hide-background::backdrop {
  background-color: #000;
  background-image: url("../../img/background-img3.png");
}

.dialog--hide-background + .backdrop {
  background-color: #000;
  background-image: url("../../img/background-img3.png");
}

.dialog__content {
  border: 0.2em solid #000;
  background-color: #fff;
  position: relative;
  width: min(80vw, 30em);
  max-width: 100%;
  background-image: url("../../img/background-img.jpg");
  margin-left: auto;
  margin-right: auto;
}
.dialog--polyfilled > .dialog__content {
  max-height: 80vh;
  overflow: auto;
}

.dialog__header {
  padding: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.dialog__title {
  font-family: var(--serif-font);
  text-shadow: -0.05em -0.05em 0.1em rgba(255, 255, 255, 0.8), 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.4);
  margin: 0;
  flex-grow: 1;
}

.dialog__hide {
  cursor: pointer;
  font-size: 2em;
  line-height: 1;
  text-shadow: -0.05em -0.05em 0.1em rgba(255, 255, 255, 0.8), 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.4);
}

.dialog__body {
  padding: 1em;
}
.dialog__header + .dialog__body {
  padding-top: 0;
}

.info-token {
  width: min(80%, 40rem);
  text-align: center;
}
.info-token > .dialog__content {
  background-color: #000;
  background-image: none;
  border-style: none;
  display: grid;
}
.info-token > .dialog__content::before {
  content: "";
  grid-area: 1/1;
  background-color: var(--colour, #000);
  opacity: 0.4;
}
.info-token > .dialog__content::after {
  content: "";
  grid-area: 1/1;
  border: 0.1em solid #fff;
  margin: 0.5em;
}
.info-token > .dialog__content > .dialog__body {
  grid-area: 1/1;
  z-index: 1;
}

.info-token__hide {
  cursor: pointer;
  font-size: 2em;
  line-height: 1;
  color: #fff;
  display: block;
  margin-left: auto;
}
[dir=rtl] .info-token__hide {
  margin-left: 0;
  margin-right: auto;
}

.info-token__text {
  font-size: clamp(1rem, 10vw, 3rem);
  margin: 0;
  color: #fff;
}
.info-token__text:lang(de), .info-token__text:lang(ru) {
  font-size: clamp(1rem, 8vw, 3rem);
}
.info-token__text > strong {
  font-family: var(--serif-font);
  font-size: 1.3em;
  letter-spacing: 0.1em;
  display: inline-block;
}

.info-token__actions {
  position: absolute;
  left: 1.2em;
  top: 1.2em;
  display: flex;
  flex-direction: row;
  gap: 1em;
}
[dir=rtl] .info-token__actions {
  left: auto;
  right: 1.2em;
}

.info-token__button {
  color: #fff;
  width: 1em;
}

.info-token-heading {
  font-family: var(--serif-font);
  font-weight: normal;
  font-size: 1em;
  text-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.8);
  margin: 1em 0;
}

.carousel {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.carousel__item {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.night-order {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.night-order__item {
  padding: 0.5em 0;
  margin: 0;
  opacity: 0.6;
  display: none;
}
.night-order__item.is-playing {
  opacity: 1;
  display: block;
}
.night-order__item.is-playing ~ .night-order__item.is-playing {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.is-show-all > .night-order__item {
  display: block;
}

.is-show-all > .night-order__item + .night-order__item {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.night-order__heading {
  display: flex;
  flex-flow: row nowrap;
  gap: 1em;
  padding-left: 0.25em;
  margin-bottom: 0.5em;
}

.night-order__icon {
  width: 2em;
  aspect-ratio: auto 177/124;
  object-fit: contain;
  cursor: pointer;
}

.night-order__role {
  margin: 0;
  font-family: var(--serif-font);
  flex-grow: 1;
}

.night-order__players {
  margin: 0;
  margin-top: 0.2rem;
  font-family: var(--serif-font);
  flex-grow: 3;
  font-size: 0.8rem;
  color: #ccc;
}

.night-order__ability {
  font-size: 0.8em;
  margin: 0;
}

@keyframes loader {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
  33% {
    background-size: 20% 10%, 20% 100%, 20% 100%;
  }
  50% {
    background-size: 20% 100%, 20% 10%, 20% 100%;
  }
  66% {
    background-size: 20% 100%, 20% 100%, 20% 10%;
  }
  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}
.loader {
  --c: no-repeat linear-gradient(currentColor 0 0);
  width: 1em;
  aspect-ratio: 1;
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: loader 1s infinite linear;
  display: inline-block;
  vertical-align: middle;
}

.tick-select__highlight {
  display: block;
  position: relative;
}
.reminder-list__item .tick-select__checkbox:checked + .tick-select__highlight::before {
  content: "✓";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 4em;
  color: #fff;
  text-shadow: 0 0 0.25em #000;
}
.reminder-list__item .tick-select__checkbox:checked + .tick-select__highlight > .tick-select__icon {
  transform: scale(0.9);
  transform-origin: center;
}

.button {
  font-family: var(--sans-font);
  font-size: 0.85em;
  line-height: inherit;
  hyphens: auto;
  width: 100%;
  padding: 0;
  background-color: var(--bg-colour, var(--blue));
  border: 0.1em solid #000;
  border-radius: 0.2em;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.3), inset -0.1em -0.1em 0.2em rgba(0, 0, 0, 0.3), 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4);
  color: #000;
  padding-left: 0.5em;
  padding-right: 0.5em;
  cursor: pointer;
}
.button[disabled] {
  opacity: 0.7;
  box-shadow: none;
  color: #333;
  border-color: #333;
  filter: grayscale(0.5);
  cursor: not-allowed;
}

.button--warning {
  background-color: var(--orange);
}

.button--full-height {
  height: 100%;
}

.is-loading > .button__text {
  display: none;
}

.button__loader {
  position: absolute;
  opacity: 0;
}
.is-loading > .button__loader {
  position: static;
  opacity: 1;
}

.button-list {
  --columns: 2;
}
@media (min-width: 650px) {
  .button-list {
    --columns: 3;
  }
}

.button-list--reminder {
  --columns: 3;
}
@media (min-width: 650px) {
  .button-list--reminder {
    --columns: 4;
  }
}
@media (min-width: 1024px) {
  .button-list--reminder {
    --columns: 6;
  }
}

.button-list--tokens {
  --columns: 3;
}

.character-list:not(.is-show-existing) > .is-in-play, .character-list:not(.is-show-travellers) > [data-team=traveller], .character-list:not(.is-show-evil) > [data-team=minion], .character-list:not(.is-show-evil) > [data-team=demon], .character-list:not(.is-show-fabled) > [data-team=fabled] {
  display: none;
}

.reminder-list__item {
  display: none;
}
.reminder-list__item.is-global, .reminder-list__item.is-in-play {
  display: block;
}
.is-show-all > .reminder-list__item {
  display: block;
}

.character-show__token {
  width: 100%;
  max-width: 12em;
  margin: 0 auto;
  display: block;
}

.character-show__ability {
  text-align: center;
}

.sidebar {
  border: 0.1em solid #000;
  padding: 0;
  margin-bottom: 1em;
  display: flex;
}

.sidebar--full {
  height: 100%;
}

.sidebar--reverse {
  flex-direction: row-reverse;
}

.sidebar__side {
  background-image: url("../../img/background-img3.png");
  color: #fff;
  font-family: var(--serif-font);
  padding: 1em 0.2em;
  margin: 0;
}

.sidebar__side-content {
  writing-mode: vertical-lr;
  text-orientation: upright;
  display: inline-block;
  position: sticky;
  top: 1em;
}
[dir=rtl] .sidebar__side-content {
  text-orientation: mixed;
}

.sidebar__body {
  padding: 1em;
  width: 100%;
}

.range {
  display: grid;
  grid-template-columns: 1fr 1.5em;
  grid-template-rows: min-content 1fr;
  gap: 0.5em 1em;
  grid-template-areas: "label label" "range output";
}

.range__label {
  grid-area: label;
}

.range__input {
  grid-area: range;
  -webkit-appearance: none;
  background-color: transparent;
  background-color: #333;
  background-image: linear-gradient(#679fd0, #679fd0);
  background-size: calc(var(--size, 50) * 1%) 100%;
  background-repeat: no-repeat;
  height: 0.5em;
  margin-top: 0.45em;
  border-radius: 0.25em;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.9), 0.05em 0.05em 0.1em rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
[dir=rtl] .range__input {
  background-position: right top;
}

.range__input::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
}
.range__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #f5f5f5;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.3), inset -0.1em -0.1em 0.2em rgba(0, 0, 0, 0.3), 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4);
  pointer-events: auto;
}
.range__input::-moz-range-track {
  width: 100%;
  cursor: pointer;
}
.range__input::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #f5f5f5;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.3), inset -0.1em -0.1em 0.2em rgba(0, 0, 0, 0.3), 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4);
  pointer-events: auto;
}

.range__output {
  grid-area: output;
  text-align: right;
}

.character-select__fieldset {
  border: none;
  padding: 0;
}

.character-select__title,
.character-select__score-box {
  display: inline-flex;
}

.character-select__score-box {
  margin-top: 1em;
}
[dir=rtl] .character-select__score-box {
  margin-bottom: 1em;
  margin-top: 0;
  display: inline-flex;
}

.character-select__score-seperator::after {
  content: attr(data-vertical);
}
[dir=rtl] .character-select__score-seperator::after {
  content: attr(data-horizontal);
}

.character-select__characters {
  --columns: 2;
}
@media (min-width: 650px) {
  .character-select__characters {
    --columns: 3;
  }
}

.character-select__name {
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
}
.character-select__name:lang(de) {
  font-size: 0.7em;
}

.character-select__image {
  aspect-ratio: 1/1;
  object-fit: contain;
}

.character-select__ability {
  display: block;
  font-size: 0.75em;
}
.is-hide-abilities .character-select__ability {
  display: none;
}

:checked ~ .character-select__name.is-setup, :checked ~ .character-select__name.is-jinx {
  position: relative;
}
:checked ~ .character-select__name.is-setup::before, :checked ~ .character-select__name.is-jinx::after {
  font-weight: bold;
  width: 1em;
  height: 1em;
  position: absolute;
  top: -1.25em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  line-height: 1;
}
:checked ~ .character-select__name.is-setup::before {
  content: "!";
  background-color: red;
  color: white;
  left: 0;
}
:checked ~ .character-select__name.is-jinx::after {
  content: "✦";
  background-color: yellow;
  color: black;
  right: 0;
}

:checked ~ .character-select__image-wrapper .character-select__image {
  background-image: radial-gradient(rgba(0, 255, 0, 0.6), transparent 80%);
  filter: drop-shadow(0 0 0.1em white);
}

.character-select__character {
  position: relative;
}

.character-select__duplicate {
  display: none;
  position: absolute;
  top: -1em;
  z-index: 1;
}
.is-show-duplicates .is-selected > .character-select__duplicate {
  display: block;
}

.character-select__count {
  width: 100%;
  text-align: center;
  border: none;
  background-color: transparent;
  font-family: var(--serif-font);
  font-size: 2em;
  text-shadow: 0 0 0.1em rgb(255, 255, 255), 0 0 0.25em rgb(255, 255, 255), 0 0 0.5em rgb(255, 255, 255);
}
.character-select__count[tabindex="-1"] {
  pointer-events: none;
}

.character-select__button {
  padding: 0.5em;
  position: absolute;
  top: 0;
}

.character-select__button--prev {
  left: 0;
}

.character-select__button--next {
  right: 0;
}

.character-select__icon {
  width: 1em;
  color: inherit;
  display: block;
}

.character-choice {
  display: block;
  width: 100%;
}
.character-choice[disabled] {
  opacity: 0.5;
  filter: grayscale(1) brightness(0.7);
}

.character-choice[data-id=lilmonsta]:enabled, .character-choice[data-id=drunk]:enabled, .character-choice[data-id=marionette]:enabled {
  background-color: red;
}

.character-choice[data-id=donkeywrangler]:enabled {
  background-color: white;
}

.input {
  --border-radius: 0.2em;
  display: block;
  border: 0.1em solid #000;
  border-radius: var(--border-radius);
  width: 100%;
  box-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4), inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.3);
  padding: 0 0.3em;
  background-color: #f5f5f5;
  font-family: var(--sans-font);
  font-size: 0.85em;
  line-height: inherit;
}

.input--tall {
  min-height: 7.7em;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.radio__wrapper {
  display: inline-block;
  width: 1em;
  aspect-ratio: 1/1;
  vertical-align: middle;
}

.radio__render {
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 50%;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.2);
  position: relative;
  border: 1px solid #666;
}

.radio__input:focus:not(:focus-visible) + .radio__render {
  outline: none;
}
.radio__input:focus-within + .radio__render {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 2px;
}
.radio__input:checked + .radio__render {
  border-color: #000;
}
.radio__input:checked + .radio__render::after {
  content: "";
  display: block;
  position: absolute;
  width: 65%;
  aspect-ratio: 1/1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #679fd0;
  border-radius: 50%;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.3), inset -0.1em -0.1em 0.2em rgba(0, 0, 0, 0.3), 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4);
}

.toggle-switch {
  --transition-duration: 0;
  --spacing: 0.1em;
  position: relative;
  display: inline-block;
  vertical-align: text-top;
}
@media (prefers-reduced-motion: no-preference) {
  .toggle-switch {
    --transition-duration: 0.2s;
  }
}

.toggle-switch__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  z-index: 1;
}

.toggle-switch__render {
  width: 2em;
  height: 1em;
  background-color: #333;
  border-radius: 1em;
  position: relative;
  display: inline-block;
  will-change: background-color;
  transition-property: background-color;
  transition-duration: var(--transition-duration);
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.9), 0.05em 0.05em 0.1em rgba(255, 255, 255, 0.2);
}
.toggle-switch__render::after {
  content: "";
  display: block;
  position: absolute;
  top: var(--spacing);
  left: var(--spacing);
  width: calc(1em - 2 * var(--spacing));
  aspect-ratio: 1/1;
  background-color: #f5f5f5;
  border-radius: 50%;
  will-change: left;
  transition-property: left, right;
  transition-duration: var(--transition-duration);
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.3), inset -0.1em -0.1em 0.2em rgba(0, 0, 0, 0.3), 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4);
}
:focus-visible + .toggle-switch__render {
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 2px;
}

:checked + .toggle-switch__render {
  background-color: #679fd0;
}
:checked + .toggle-switch__render::after {
  left: calc(100% - (1em - 2 * var(--spacing)) - var(--spacing));
}

[dir=rtl] .toggle-switch__render::after {
  left: auto;
  right: var(--spacing);
}

[dir=rtl] :checked + .toggle-switch__render::after {
  left: auto;
  right: calc(100% - (1em - 2 * var(--spacing)) - var(--spacing));
}

.file-upload {
  display: grid;
}

.file-upload__input,
.file-upload__render {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
}

.file-upload__input {
  z-index: 1;
  opacity: 0;
}

.file-upload__render {
  --border-radius: 0.2em;
  display: flex;
  box-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.4);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-family: var(--sans-font);
  font-size: 0.85em;
  min-width: 0;
}
.file-upload__render::before {
  content: attr(data-value);
  flex-grow: 1;
  border: 0.1em solid #000;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.3);
  padding: 0 0.3em;
  background-color: #f5f5f5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.file-upload__render::after {
  content: attr(data-button);
  border: 0.1em solid #000;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  background-color: #679fd0;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.3), inset -0.1em -0.1em 0.2em rgba(0, 0, 0, 0.3);
  padding: 0 0.3em;
}
:focus-visible + .file-upload__render {
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 2px;
}

[dir=rtl] .file-upload__render::before {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
[dir=rtl] .file-upload__render::after {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.settable-title__title {
  font-family: inherit;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-shadow: inherit;
}

.settable-title__input {
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline;
  font-family: inherit;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-shadow: inherit;
}

.settable-title__input--full {
  display: block;
  width: 100%;
}

.bluffs__heading {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1rem;
}

.bluffs__title {
  flex-grow: 1;
  margin-bottom: 0;
}

[data-group-id="0"] .bluffs__remove {
  display: none;
}

.bluffs__list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.bluffs__input {
  flex-grow: 1;
  width: auto;
}

.switch {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5em;
}

.switch__label {
  width: 100%;
}

.jinxes {
  border-collapse: collapse;
}

.jinxes__jinx {
  opacity: 0.6;
  display: none;
}
.jinxes__jinx.is-active {
  opacity: 1;
  display: table-row;
}
.jinxes__jinx.is-active ~ .jinxes__jinx.is-active > .jinxes__cell,
.is-show-all > .jinxes__jinx ~ .jinxes__jinx > .jinxes__cell {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.is-show-all > .jinxes__jinx ~ .jinxes__jinx > .jinxes__cell {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.is-show-all > .jinxes__jinx {
  display: table-row;
}

.jinxes__cell {
  padding: 0.5em 0.2em;
}

.jinxes__cell--text {
  font-size: 0.8em;
}

.jinxes__icon {
  max-width: 4em;
  display: block;
  margin: 0 auto;
}

.jinxes__name {
  font-family: var(--serif-font);
  font-size: 0.5em;
  display: block;
  text-align: center;
  margin-top: 0.5em;
}

.qr {
  display: block;
}
.qr > svg {
  display: block;
  background-color: #fff;
  margin: 0 auto;
}

.locale-form {
  --gap: 1em;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--gap);
  margin-top: var(--gap);
}
@media print {
  .locale-form {
    display: none;
  }
}

.locale-form--setup {
  border-top: 1px solid #ccc;
  padding-top: var(--gap);
}

.locale-form--sheet {
  margin-bottom: var(--gap);
}

.locale-form__label,
.locale-form__action {
  display: flex;
  justify-content: center;
}

.locale-form__action {
  flex: 0 0 1em;
}

.locale-form__dropdown {
  flex: 1 1 auto;
}

.locale-form__label-icon {
  width: 1.5em;
}

.locale-form__icon {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  width: 0.85em;
}

.token-display {
  justify-content: center;
}
.token-display.is-multiple {
  --columns: 2;
}

.token-display__item {
  position: relative;
}
.token-display__item.is-draggable {
  cursor: move;
  touch-action: none;
}
.token-display__item.is-dragging {
  opacity: 0.5;
}

.token-display__remove {
  display: none;
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  padding: 0.5em;
  z-index: 1;
}
.is-multiple .token-display__remove {
  display: block;
}

[dir=rtl] .token-display__remove {
  left: -0.5em;
  right: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-2 {
  margin-top: 0.5em !important;
}

.mt-4 {
  margin-top: 1em !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: 1em !important;
}

.p-2 {
  padding: 0.5em !important;
}

.d-block {
  display: block !important;
}

.bd-dark {
  border: 0.1em solid #666 !important;
}

.w-full {
  width: 100% !important;
}

.text-small {
  font-size: 0.85em !important;
}