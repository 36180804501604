.toggle-switch {
    --transition-duration: 0;
    --spacing: 0.1em;
    position: relative;
    display: inline-block;
    vertical-align: text-top;

    @media (prefers-reduced-motion: no-preference) {
        --transition-duration: 0.2s;
    }
}

.toggle-switch__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
    z-index: 1;
}

.toggle-switch__render {
    width: 2em;
    height: 1em;
    background-color: #333;
    border-radius: 1em;
    position: relative;
    display: inline-block;
    will-change: background-color;
    transition-property: background-color;
    transition-duration: var(--transition-duration);
    box-shadow:
        inset 0.1em 0.1em 0.2em rgb(0 0 0 / 90%),
        0.05em 0.05em 0.1em rgb(255 255 255 / 20%);

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        width: calc(1em - (2 * var(--spacing)));
        aspect-ratio: 1 / 1;
        background-color: #f5f5f5;
        border-radius: 50%;
        will-change: left;
        transition-property:
            left,
            right;
        transition-duration: var(--transition-duration);
        box-shadow:
            inset 0.1em 0.1em 0.2em rgb(255 255 255 / 30%),
            inset -0.1em -0.1em 0.2em rgb(0 0 0 / 30%),
            0.1em 0.1em 0.2em rgb(0 0 0 / 40%);
    }

    @at-root {
        :focus-visible + & {
            outline: Highlight auto 1px;
            outline: -webkit-focus-ring-color auto 1px;
            outline-offset: 2px;
        }

        :checked + & {
            background-color: #679fd0;

            &::after {
                left: calc(100% - (1em - (2 * var(--spacing))) - var(--spacing));
            }
        }

        [dir="rtl"] &::after {
            left: auto;
            right: var(--spacing);
        }

        [dir="rtl"] :checked + &::after {
            left: auto;
            right: calc(100% - (1em - (2 * var(--spacing))) - var(--spacing));
        }

    }
}
