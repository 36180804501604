.details {
    color: #fff;
    background-color: rgb(0 0 0 / 70%);
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow:
        inset 0.15em 0.15em 0.3em rgb(0 0 0 / 80%),
        0.1em 0.1em 0.2em rgb(255 255 255 / 80%);
    margin-bottom: 1em;
}

.details__summary {
    font-family: var(--serif-font);
    text-shadow: 0.1em 0.1em 0.1em rgb(0 0 0 / 80%);
    cursor: pointer;
}

.details__heading {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    display: inline;
}

.details__body {
    padding-top: 1em;
    padding-bottom: 0.5em;
}
