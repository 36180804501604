.pad {
    border: 0.15em solid #000;
    position: relative;
    background-color: #333;
    background-image: url("../../img/background-img2.jpg");
}

.pad--growable {
    height: 80vh;
    resize: vertical;
    overflow: scroll;
}

.pad--bluffs {
    padding: 0.5em;
}

.pad-wrapper {
    position: relative;
    margin-bottom: 1em;
}

.pad-wrapper__icon {
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0.15em;
    pointer-events: none;
    width: 1em;
    aspect-ratio: 1 / 1;

    @at-root {
        [dir="rtl"] & {
            right: auto;
            left: 0;
        }
    }
}

.pad__title {
    font-family: var(--serif-font);
    font-weight: normal;
    font-size: 1rem;
    text-shadow: 0.1em 0.1em 0.1em rgb(0 0 0 / 80%);
}
