.night-order {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.night-order__item {
    padding: 0.5em 0;
    margin: 0;
    opacity: 0.6;
    display: none;

    &.is-playing {
        opacity: 1;
        display: block;

        @at-root {
            & ~ & {
                border-top: 1px solid rgb(255 255 255 / 60%);
            }
        }
    }

    @at-root {
        .is-show-all > & {
            display: block;

        }
        .is-show-all > & + & {
            border-top: 1px solid rgb(255 255 255 / 60%);
        }
    }
}

.night-order__heading {
    display: flex;
    flex-flow: row nowrap;
    gap: 1em;
    padding-left: 0.25em;
    margin-bottom: 0.5em;
}

.night-order__icon {
    width: 2em;
    aspect-ratio: auto 177 / 124;
    object-fit: contain;
    cursor: pointer;
}

.night-order__role {
    margin: 0;
    font-family: var(--serif-font);
    flex-grow: 1;
}

.night-order__players {
    margin: 0;
    margin-top: 0.2rem;
    font-family: var(--serif-font);
    flex-grow: 3;
    font-size: 0.8rem;
    color: #ccc;
}

.night-order__ability {
    font-size: 0.8em;
    margin: 0;
}
